import React, { useState } from 'react';
import { Card, CardHeader, CardContent, IconButton, Menu, MenuItem, Typography, Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './HistoryCard.scss';

function HistoryCard({ historyItem, personaName, onDelete }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        handleMenuClose();
        onDelete(historyItem);
    };

    return (
        <Card sx={{ marginBottom: 2 }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleMenuOpen}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={historyItem.role === "user" ? "You" : personaName}
            />
            <CardContent>
                <Typography>
                    {historyItem.content}
                </Typography>
            </CardContent>
            <CardContent sx={{ borderTop: 1, borderColor: 'divider' }}>
                <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1" color="text.secondary">
                        {historyItem.timeSent}
                    </Typography>
                </Grid>
            </CardContent>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </Card>
    );
};

export default HistoryCard;