import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../components/PageTitle/PageTitle';
import { createPersona, getPersonas, deletePersona } from '../api/PersonaApi';
import { Container, Box, Button, Alert } from '@mui/material';
import { getMembershipLevel } from '../api/UserApi';
import PersonaCard from '../components/PersonaCard/PersonaCard';
import NewPersonaDialog from '../components/NewPersonaDialog/NewPersonaDialog';
import ConfirmDialogDialog from '../components/ConfirmDialog/ConfirmDialog';
import { toast } from 'react-toastify';

function Personas() {
    const navigate = useNavigate();
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
    const [personas, setPersonas] = useState([]);
    const [membershipLevel, setMembershipLevel] = useState("Free");
    const [selectedPersona, setSelectedPersona] = useState({
        id: '',
        name: ''
    });

    const fetchPersonas = async () => {
        try {
            const data = await getPersonas();

            setPersonas(data);
        } catch (error) {
            toast.error(`Failed to fetch personas: ${error}`);
        }
    };

    useEffect(() => {
        fetchPersonas();
        setMembershipLevel(getMembershipLevel());
    }, []);

    const promptDeletePersona = (persona) => {
        setSelectedPersona(persona);
        setIsConfirmDeleteOpen(true);
    };

    const handleDeletePersona = async (result) => {
        setIsConfirmDeleteOpen(false);
        
        if (result === "Yes") {
            try {
                await deletePersona(selectedPersona.id);

                toast.success("Persona deleted successfully.");
    
                fetchPersonas();
            }
            catch (error) {
                toast.error(`${error}`);
            }
        }
    };

    const handleNewPersonaOpen = () => {
        setSelectedPersona( { id: '', name: '' } )
        setIsNewDialogOpen(true);
    };

    const handleNewDialogClose = () => {
        setIsNewDialogOpen(false);
    };

    const handleNewDialogSave = async (persona) => {
        try {
            await createPersona(persona.name, persona.relationship);

            toast.success("Persona created successfully.");

            fetchPersonas();
        }
        catch (error) {
            toast.error(`${error}`);
        }

        setIsNewDialogOpen(false);
    };

    const handleEditEngrams = (persona) => {
        navigate(`/persona/engrams/${persona.id}`);
    };

    const handleHistory = (persona) => {
        navigate(`/persona/history/${persona.id}`);
    };

    const handleProfile = (persona) => {
        navigate(`/persona/profile/${persona.id}`);
    };

    return (
        <Container component="main" maxWidth="2xl">
            <Box>
                <PageTitle title="Personas" />
                {membershipLevel === "Free" && 
                    <Box mb={2}>
                        <Alert severity="warning">
                            Your membership is free, allowing you to only have one persona. 
                        </Alert>
                    </Box>
                }
                <Box mb={2}>
                    <Button variant='contained' onClick={handleNewPersonaOpen} disabled={membershipLevel === 'Free' && personas.length > 0}> 
                        New Persona
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {personas.map((persona) => (
                        <PersonaCard
                            key={persona.id}
                            persona={persona}
                            onEditEngrams={handleEditEngrams}
                            onManageHistory={handleHistory}
                            onEditProfile={handleProfile}
                            onDelete={promptDeletePersona}
                        />
                    ))}
                </Box>
            </Box>
            <NewPersonaDialog open={isNewDialogOpen} onClose={handleNewDialogClose} onSave={handleNewDialogSave} personaData={selectedPersona} />
            <ConfirmDialogDialog 
                open={isConfirmDeleteOpen} 
                onClose={handleDeletePersona} 
                title="Delete persona" 
                message="Are you sure you want to delete this persona? This cannot be undone." 
            />
        </Container>
    );
}

export default Personas;