import React, { useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Box, Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAvatarURL } from './api/UserApi';
import { useAuth } from './AuthContext';  // Import the useAuth hook

function Layout() {
  const { user, logout } = useAuth();  // Access the user and logout function from AuthContext
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
    navigate('/login');  // Redirect to login page after logout
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div">
            Heirloom Chat
          </Typography>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Link to="/" style={{ color: 'white', textDecoration: 'none', marginRight: '16px', marginLeft: '16px' }}>Home</Link>
            <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>About</Link>
            {user && (
              <Link to="/personas" style={{ color: 'white', textDecoration: 'none', marginRight: '16px', marginLeft: '16px' }}>Persona Dashboard</Link>
            )}
          </Box>
          <Box sx={{ ml: 'auto' }}>
            {user ? (
              <>
                <IconButton onClick={handleMenu} size="large" sx={{ p: 0 }}>
                  <Avatar src={getAvatarURL()} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => {navigate('/membership'); handleClose();}}>Manage Membership</MenuItem>
                  <MenuItem onClick={() => {navigate('/profile'); handleClose();}}>Edit Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Link to="/login" style={{ color: 'white', textDecoration: 'none', marginRight: '16px' }}>Login</Link>
                <Link to="/register" style={{ color: 'white', textDecoration: 'none' }}>Register</Link>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Container>
        <Outlet />
      </Container>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Layout;