import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

function NewEngramDialog({ open, onClose, onSave, engramData }) {
    const [formData, setFormData] = useState({ id: '', engram: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
            <DialogTitle>New Engram</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="engram"
                    name="engram"
                    label="Engram Data"
                    type="text"
                    fullWidth
                    variant="standard"
                    rows={8}
                    multiline
                    value={formData.engram}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">Cancel</Button>
                <Button onClick={handleSave} variant="contained" color="success">Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewEngramDialog;