import React, { useState } from 'react';
import { requestPasswordReset } from '../api/UserApi';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Avatar, Typography, TextField, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { toast } from 'react-toastify';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleReset = async () => {
        try {
            await requestPasswordReset(email);
            
            toast.success("Password reset link sent to user's email address.");
            navigate("/");
        }
        catch (error) {
            toast.error(`There was an error submitting your request. ${error}`);
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        id="email" 
                        label="Email Address" 
                        name="email" 
                        autoComplete="email" 
                        autoFocus 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleReset}>
                        Reset Password
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default ForgotPassword;