import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

function ConfirmDialogDialog({ open, onClose, title, message }) {
    const handleClose = (result) => {
        onClose(result)
    };

    return(
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose('No')} variant="contained" color="secondary">No</Button>
                <Button onClick={() => handleClose('Yes')} variant="contained" color="success">Yes</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialogDialog;