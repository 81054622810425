import React from 'react';
import PageTitle from '../components/PageTitle/PageTitle';
import { Container, Box, Typography } from '@mui/material';

function About() {
  return (
    <Container component="main" maxWidth="2xl">
        <Box>
            <PageTitle title="About Heirloom Chat" />
        </Box>
        <Box mt={4} mb={4}>
          <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
            Heirloom Chat is dedicated to preserving and celebrating the legacy of loved ones through conversation. Our innovative platform allows users to create and 
            interact with Personas—digital reflections of individuals based on their memories and personalities.
          </Typography>
          <Typography variant="h5" component="h2" sx={{ mt: 4, mb: 2, fontWeight: 'bold' }}>
            Our Mission
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Our mission is to keep the memory and essence of loved ones alive, making it possible for future generations to experience their wisdom, humor, and stories. 
            We strive to provide a space where past relationships continue to enrich lives.
          </Typography>
          <Typography variant="h5" component="h2" sx={{ mt: 4, mb: 2, fontWeight: 'bold' }}>
            The Technology
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            At the core of Heirloom Chat is our advanced AI and machine learning technology, which crafts realistic and responsive digital personas from user-provided 
            data. This technology not only captures the essence of conversations but also adapts and learns to ensure authentic and engaging interactions.
          </Typography>
          <Typography variant="h5" component="h2" sx={{ mt: 4, mb: 2, fontWeight: 'bold' }}>
            Our Team
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Our team is composed of dedicated engineers, designers, and storytellers passionate about making a difference. Each member brings unique expertise and a 
            shared commitment to our vision, driving us forward each day.
          </Typography>
        </Box>
    </Container>
  );
}

export default About;