import React, { useState } from 'react';
import { Card, CardHeader, CardContent, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function EngramCard({ engram, onDelete }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        handleMenuClose();
        onDelete(engram);
    };

    return (
        <Card sx={{ marginBottom: 2 }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleMenuOpen}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title="Memory Engram"
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {engram.engram}
                </Typography>
            </CardContent>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </Card>
    );
};

export default EngramCard;