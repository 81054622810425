import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_APP_API_URL}/api/personas`;

export async function createPersona(name, relationship) {
    var authToken = localStorage.getItem('token');
    var postBody = {
        name: name,
        relationship: relationship
    }

    try {
        await axios.post(`${API_BASE_URL}`, postBody, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function getPersonas() {
    var authToken = localStorage.getItem('token');

    try {
        const response = await axios.get(`${API_BASE_URL}`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function deletePersona(personaId) {
    var authToken = localStorage.getItem('token');

    try {
        const response = await axios.delete(`${API_BASE_URL}/${personaId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function getPersona(personaId) {
    var authToken = localStorage.getItem('token');
    
    try {
        const response = await axios.get(`${API_BASE_URL}/${personaId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function updatePersona(personaId, persona) {
    var authToken = localStorage.getItem('token');
    
    var postBody = {
        name: persona.name,
        relationship: persona.relationship
    }

    try {
        await axios.post(`${API_BASE_URL}/${personaId}`, postBody, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function sendChat(personaId, message) {
    var authToken = localStorage.getItem('token');

    var postBody = {
        message: message
    };

    try {
        const response = await axios.post(`${API_BASE_URL}/${personaId}/chat`, postBody, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function getHistory(personaId) {
    var authToken = localStorage.getItem('token');

    try {
        const response = await axios.get(`${API_BASE_URL}/${personaId}/chat/history`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function deleteHistoryItem(personaId, historyId) {
    var authToken = localStorage.getItem('token');

    try {
        const response = await axios.delete(`${API_BASE_URL}/${personaId}/chat/history/${historyId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function uploadPersonaAvatar(personaId, blob) {
    var authToken = localStorage.getItem('token');
    const formData = new FormData();

    formData.append("file", blob, "croppedImage.png");

    try {
        await axios.post(`${API_BASE_URL}/${personaId}/avatar`, formData, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data',
            }
        });
    }
    catch (error) {
        throw error.response.data;
    }
}