import React, { useRef, useState, useCallback } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slider } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const AvatarEditorDialog = ({ open, onClose, onSave }) => {
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const editorRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };

  const handleSave = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      const blob = await fetch(canvas).then(res => res.blob());
      
      onSave(blob);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Avatar</DialogTitle>
      <DialogContent>
        <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
          <input {...getInputProps()} />
          <p>Drag & drop an image here, or click to select one</p>
        </div>
        {image && (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <AvatarEditor
              ref={editorRef}
              image={image}
              width={250}
              height={250}
              border={0}
              borderRadius={125} // Circular crop
              scale={scale}
            />
            <Slider
              value={scale}
              min={1}
              max={2}
              step={0.01}
              onChange={handleScaleChange}
              style={{ marginTop: '20px' }}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarEditorDialog;
