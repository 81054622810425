import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const initializeAuth = async () => {
            const token = localStorage.getItem('token');

            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_APP_API_URL}/api/users/validate`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    setUser(response.data);
                } 
                catch (error) {
                    localStorage.removeItem('token');
                }
            }
        };

        initializeAuth();
    }, []);

    const login = async (email, password) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_APP_API_URL}/api/users/login`, {
                emailAddress: email,
                password: password
            });

            const data = response.data;

            setUser(data);

            localStorage.setItem('token', data.token);
        } catch (error) {
            throw new Error(error.response?.data || 'Unable to login');
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};