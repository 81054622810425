import React from 'react';
import { Avatar } from '@mui/material';
import './PageTitle.scss';

function PageTitle({ title, avatar }) {
    return (
        <div className="pageTitle">
            {avatar && <Avatar src={avatar} alt={title} className="avatar" />}
            <div className="titleContainer">
                <h1>{title}</h1>
                <hr />
            </div>
        </div>
    );
}

export default PageTitle;