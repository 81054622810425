import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, FormControl, InputLabel, MenuItem, Grid } from '@mui/material';

function NewPersonaDialog({ open, onClose, onSave, personaData }) {
    const [formData, setFormData] = useState(personaData);

    useEffect(() => {
        if (personaData) {
            setFormData(personaData);
        }
    }, [personaData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
            <DialogTitle>New Persona</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={30}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Persona Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={30}>
                        <FormControl fullWidth>
                            <InputLabel id="relationship-label">Relationship</InputLabel>
                            <Select
                                labelId="relationship-label"
                                label="Relationship"
                                id="relationship"
                                name="relationship"
                                value={formData.relationship}
                                onChange={handleChange}
                            >
                                <MenuItem value={"Mother"}>Mother</MenuItem>
                                <MenuItem value={"Father"}>Father</MenuItem>
                                <MenuItem value={"Sister"}>Sister</MenuItem>
                                <MenuItem value={"Brother"}>Brother</MenuItem>
                                <MenuItem value={"Grandmother"}>Grandmother</MenuItem>
                                <MenuItem value={"Grandfather"}>Grandfather</MenuItem>
                                <MenuItem value={"Friend"}>Friend</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">Cancel</Button>
                <Button onClick={handleSave} variant="contained" color="success">Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewPersonaDialog;