import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getPersona, sendChat, getHistory } from '../api/PersonaApi';
import { getMembershipLevel } from '../api/UserApi';
import { Container, Box, List, ListItem, ListItemText, TextField, Button, Divider, Alert } from '@mui/material';
import PageTitle from '../components/PageTitle/PageTitle';
import { toast } from 'react-toastify';

function PersonaChat() {
    const { id } = useParams();
    const [persona, setPersona] = useState({ id: '', name: ''});
    const [messages, setMessages] = useState([]);
    const [membershipLevel, setMembershipLevel] = useState("Free");
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const listRef = useRef(null);

    const handleSendMessage = async () => {
        if (input.trim()) {
            const userMessage = { text: input, sender: "You", timestamp: new Date().toLocaleTimeString() };

            setMessages((prevMessages) => [...prevMessages, userMessage]);
            setInput('');
            setIsTyping(true);

            try {
                const data = await sendChat(id, userMessage.text);               

                const personaResponse = { text: data.message, sender: persona.name, timestamp: new Date().toUTCString() };

                setMessages((prevMessages) => [...prevMessages, personaResponse]);
            } 
            catch (error) {
                toast.error(`Failed to send message: ${error}`);
            }
            finally {
                setIsTyping(false);
            }
        }
    };

    useEffect(() => {
        async function initialFetch() {
            try {
                const personaData = await getPersona(id);
                const messageHistory = await getHistory(id);
    
                const historyMessages = messageHistory.map(item => ({
                    text: item.content,
                    sender: item.role === "user" ? "You" : personaData.name,
                    timestamp: item.timeSent
                }));
    
                setMessages(historyMessages);
                setPersona(personaData);
            } catch (error) {
                toast.error(`Failed to fetch personas: ${error}`);
            }
        }

        setMembershipLevel(getMembershipLevel());
        initialFetch();
    }, [id]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <Container component="main" maxWidth="2xl">
            <Box>
                <PageTitle avatar={persona.avatarURL || persona.name.charAt(0)} title={`${persona.name} Chat`} />
                <Box mb={2}>
                    {membershipLevel === "Free" && 
                        <Alert severity="warning">
                            Your membership is free, allowing you to send unlimited messages. 
                            However, you can only view the most recent 10 messages in your chat history, as older messages will not be preserved.
                        </Alert>
                    }
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '75vh' }}>
                    <List ref={listRef} sx={{ flexGrow: 1, overflow: 'auto', bgcolor: 'background.paper', display: 'flex', flexDirection: 'column' }}>
                        {messages.map((message, index) => (
                            <ListItem 
                                key={index} 
                                sx={{ 
                                        border: 1, 
                                        padding: 2, 
                                        marginTop: 2, 
                                        borderRadius: 5, 
                                        borderColor: '#e0e0e0', 
                                        backgroundColor: message.sender === "You" ? '#1976d2' : '#e0e0e0',
                                        color: message.sender === "You" ? '#ffffff' : '#000000',
                                        width: '80%',
                                        alignSelf: message.sender === "You" ? 'flex-start' : 'flex-end'
                                    }}
                                >
                                <ListItemText primary={message.text} secondary={`Sent at ${message.timestamp}`} />
                            </ListItem>
                        ))}
                        {isTyping && (
                            <ListItem>
                                <ListItemText primary="Typing..." />
                            </ListItem>
                        )}
                    </List>
                    <Divider />
                    <Box sx={{ display: 'flex', padding: 2 }}>
                        <TextField
                            fullWidth
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Type your message..."
                            variant="outlined"
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        />
                        <Button variant="contained" sx={{ ml: 2 }} onClick={handleSendMessage}>
                            Send
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default PersonaChat;