import React, { useEffect, useState } from 'react';
import { getProfile, saveProfile, uploadUserAvatar } from '../api/UserApi';
import PageTitle from '../components/PageTitle/PageTitle';
import { Container, Box, MenuItem, Grid, TextField, FormControl, InputLabel, Select, Button, Avatar } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputMask from 'react-input-mask';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { us_states } from '../data/USStates';
import AvatarEditorDialog from '../components/AvatarEditorDialog/AvatarEditorDialog';

function Profile() {
  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({
    dateOfBirth: null,
    gender: '',
    phoneNumber: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  });

  const fetchProfile = async () => {
    try {
      const data = await getProfile();
      
      if (data.dateOfBirth) {
        data.dateOfBirth = parseISO(data.dateOfBirth);
      }

      setUserProfile(data);
    }
    catch (error) {
      toast.error(`Failed to fetch user profile: ${error}`);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleDateChange = (newDate) => {
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      dateOfBirth: newDate,
    }));
  };

  const handleOpenDialog = () => {
    setAvatarDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setAvatarDialogOpen(false);
  };

  const handleAvatarSave = async (blob) => {
    try {
        await uploadUserAvatar(blob);

        await fetchProfile();

        toast.success("Avatar has been updated.");
    }
    catch (error) {
        toast.error("Avatar failed to update");
    }
  }

  const handleSave = async () => {
    try {
      await saveProfile(userProfile);
      
      fetchProfile();

      toast.success("User profile updated.");
    }
    catch (error) {
      toast.error("Error updating user profile.");
    }
  };

  return (
    <Container component="main" maxWidth="2xl">
      <Box>
        <PageTitle title="Profile" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="center">
            <Grid container xs={5}>
              <Grid item xs={12}>
                <Avatar src={userProfile.avatarURL || undefined} sx={{ width: 112, height: 112 }} />
                <AvatarEditorDialog open={avatarDialogOpen} onClose={handleCloseDialog} onSave={handleAvatarSave} />
              </Grid>
              <Grid item xs={12} sx={{marginLeft: "-10px"}}>
                <Button onClick={handleOpenDialog}>Change Avatar</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of Birth"
                  value={userProfile.dateOfBirth}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  id="gender"
                  name="gender"
                  value={userProfile.gender}
                  onChange={handleChange}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="999-999-9999"
                value={userProfile.phoneNumber}
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="streetAddress1"
                name="streetAddress1"
                label="Street Address 1"
                value={userProfile.streetAddress1}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="streetAddress2"
                name="streetAddress2"
                label="Street Address 2"
                value={userProfile.streetAddress2}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="city"
                name="city"
                label="City"
                value={userProfile.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  id="state"
                  name="state"
                  value={userProfile.state}
                  onChange={handleChange}
                  label="State"
                  fullWidth
                >
                  {us_states.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="zipCode"
                name="zipCode"
                label="Zip Code"
                value={userProfile.zipCode}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="country"
                name="country"
                label="Country"
                value={"USA"}
                onChange={handleChange}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleSave}>Save Profile</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Profile;