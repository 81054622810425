import React, { useEffect, useState } from 'react';
import { getMembershipLevel } from '../api/UserApi';
import PageTitle from '../components/PageTitle/PageTitle';
import { Container, Box, Card, CardContent,Typography, List, ListItem, ListItemText, Button, Alert } from '@mui/material';

function Membership() {
    const [membershipLevel, setMembershipLevel] = useState("Free");

    useEffect(() => {
        setMembershipLevel(getMembershipLevel);
    }, []);

    const getCardStyles = (level) => {
        if (level === membershipLevel) {
            return {
                minWidth: 275,
                textAlign: 'center',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
            };
        }
        return {
            minWidth: 275,
            textAlign: 'center',
        };
    };

    const renderButtonOrCurrentPlan = (level) => {
        return membershipLevel === level ? (
            <Typography>
                <span style={{ background: '#ffce00', borderRadius: '12px', color: '#000', fontSize: '12px', padding: '5px 8px', marginLeft: '10px' }}>Current Plan</span>
            </Typography>
        ) : (
            <Button variant="outlined" color="primary" onClick={() => { setMembershipLevel(level); }} disabled={true}>
                Choose Plan
            </Button>
        );
      };

    return(
        <Container component="main" maxWidth="2xl">
            <Box>
                <PageTitle title="Membership" />
            </Box>
            <Box>
                <Alert severity="info">You are unable to update membership levels at this time.</Alert>
            </Box>
            <Box display="flex" justifyContent="center" gap={2} padding={2}>
                <Card sx={ getCardStyles("Free") }>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Free
                        </Typography>
                        <Typography variant="h4" component="div" gutterBottom>
                            $0 <span style={{ fontSize: '16px' }}>per month</span>
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="1 Persona" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="2 Memory Engrams" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="10 Chat History Engrams" />
                            </ListItem>
                        </List>
                        {renderButtonOrCurrentPlan("Free")}
                    </CardContent>
                </Card>

                <Card sx={ getCardStyles("Standard") }>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Standard
                        </Typography>
                        <Typography variant="h4" component="div" gutterBottom>
                            $10 <span style={{ fontSize: '16px' }}>per month</span>
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Unlimited Personas" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Unlimited Engrams" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Unlimited Chat History Engrams" />
                            </ListItem>
                        </List>
                        {renderButtonOrCurrentPlan("Standard")}
                    </CardContent>
                </Card>

                <Card sx={ getCardStyles("Shared") }>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Shared
                        </Typography>
                        <Typography variant="h4" component="div" gutterBottom>
                            $20 <span style={{ fontSize: '16px' }}>per month</span>
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Unlimited Personas and Engrams" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Unlimited Chat History Engrams" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Shared with 3 other accounts" />
                            </ListItem>
                        </List>
                        {renderButtonOrCurrentPlan("Shared")}
                    </CardContent>
                </Card>
            </Box>
            <Box>
                <Button variant="contained" disabled={true}>
                    Save Plan Changes
                </Button>
            </Box>
        </Container>
    );
};

export default Membership;