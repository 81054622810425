import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_APP_API_URL}/api/engrams`;

export async function getEngrams(personaId) {
    var authToken = localStorage.getItem('token');

    try {
        const response = await axios.get(`${API_BASE_URL}?personaId=${personaId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });

        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function deleteEngram(engramId) {
    var authToken = localStorage.getItem('token');

    try {
        await axios.delete(`${API_BASE_URL}/${engramId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function createEngram(personaId, engram) {
    var authToken = localStorage.getItem('token');
    var postBody = {
        personaId: personaId,
        engram: engram
    }

    try {
        await axios.post(`${API_BASE_URL}`, postBody, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
    }
    catch (error) {
        throw error.response.data;
    }
}