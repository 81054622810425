import axios from "axios";
import { jwtDecode } from 'jwt-decode';

const API_BASE_URL = `${process.env.REACT_APP_APP_API_URL}/api/users`;

export async function registerUser(userData) {
    var postBody = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        emailAddress: userData.email,
        password: userData.password
    }

    try {
        await axios.post(`${API_BASE_URL}/register`, postBody);
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function verifyUser(userToken) {
    try {
        const response = await axios.get(`${API_BASE_URL}/verify-user/${userToken}`);
        
        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function getProfile() {
    var authToken = localStorage.getItem('token');

    try {
        const response = await axios.get(`${API_BASE_URL}/profile`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
        
        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function saveProfile(profileObject) {
    var authToken = localStorage.getItem('token');

    try {
        const response = await axios.post(`${API_BASE_URL}/profile`, profileObject, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
        
        return response.data;
    }
    catch (error) {
        throw error.response.data;
    }
}

export function getMembershipLevel() {
    var authToken = localStorage.getItem('token');

    if (authToken) {
        const decodedToken = jwtDecode(authToken);

        return decodedToken.Membership;
    }
    else {
        return '';
    }
}

export function getAvatarURL() {
    var authToken = localStorage.getItem('token');

    if (authToken) {
        const decodedToken = jwtDecode(authToken);

        return decodedToken.AvatarURL;
    }
    else {
        return undefined;
    }
}

export async function uploadUserAvatar(blob) {
    var authToken = localStorage.getItem('token');
    const formData = new FormData();

    formData.append("file", blob, "croppedImage.png");

    try {
        await axios.post(`${API_BASE_URL}/profile/avatar`, formData, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data',
            }
        });
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function requestPasswordReset(emailAddress) {
    var postBody = {
        emailAddress: emailAddress
    }

    try {
        await axios.post(`${API_BASE_URL}/sendreset`, postBody);
    }
    catch (error) {
        throw error.response.data;
    }
}

export async function resetPassword(userToken, password) {
    var postBody = {
        password: password
    }
    
    try {
        await axios.post(`${API_BASE_URL}/reset-password/${userToken}`, postBody);
    }
    catch (error) {
        throw error.response.data;
    }
}