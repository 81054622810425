import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { verifyUser } from '../api/UserApi';
import PageTitle from '../components/PageTitle/PageTitle';
import { Container, Box, Alert, Link } from '@mui/material';

function VerifyUser() {
    const { userToken } = useParams();

    useEffect(() => {
        verifyUser(userToken);
    }, [userToken]);

    return (
        <Container component="main" maxWidth="2xl">
            <Box>
                <PageTitle title="Verify User" />
            </Box>
            <Box>
                <Alert severity="success">
                    User account has been verified. 
                    <Link href="/login" variant="body2">
                        Click here to login.
                    </Link>
                </Alert>
            </Box>
        </Container>
    );
}

export default VerifyUser;