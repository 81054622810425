import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPersona, updatePersona, uploadPersonaAvatar } from '../api/PersonaApi';
import PageTitle from '../components/PageTitle/PageTitle';
import { Container, Box, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Avatar } from '@mui/material';
import AvatarEditorDialog from '../components/AvatarEditorDialog/AvatarEditorDialog';
import { toast } from 'react-toastify';

function PersonaProfile() {
    const { id } = useParams();
    const [persona, setPersona] = useState({ id: '', name: '', relationship: '' });
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const fetchPersona = async () => {
            try {
                const data = await getPersona(id);
                setPersona(data);
            }
            catch (error) {
                toast.error(`Failed to fetch user profile: ${error}`);
            }
        };

        fetchPersona();
    }, [id]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPersona((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };
    
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleAvatarSave = async (blob) => {
        try {
            await uploadPersonaAvatar(persona.id, blob);

            const data = await getPersona(id);
            
            setPersona(data);

            toast.success("Avatar has been updated.");
        }
        catch (error) {
            toast.error("Avatar failed to update");
        }
    }

    const handleSave = async () => {
        try {
            updatePersona(persona.id, persona);

            toast.success("Persona updated.");
        }
        catch (error) {
            toast.error("Unable to save persona profile.");
        }
    };

    return (
        <Container component="main" maxWidth="2xl">
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PageTitle title={`${persona.name}'s Profile`} />
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%" >
                            <Grid container xs={5}>
                                <Grid item xs={12}>
                                    <Avatar src={persona.avatarURL || persona.name.charAt(0)} sx={{ width: 112, height: 112 }} />
                                </Grid>
                                <Grid item xs={12} sx={{marginLeft: "-10px"}}>
                                    <Button onClick={handleOpenDialog}>Change Avatar</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                id="name"
                                name="name"
                                label="Name"
                                value={persona.name}
                                fullWidth
                                onChange={handleChange}
                                disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="relationship-label">Relationship</InputLabel>
                                    <Select
                                        labelId="relationship-label"
                                        label="Relationship"
                                        id="relationship"
                                        name="relationship"
                                        value={persona.relationship}
                                        onChange={handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value={"Mother"}>Mother</MenuItem>
                                        <MenuItem value={"Father"}>Father</MenuItem>
                                        <MenuItem value={"Sister"}>Sister</MenuItem>
                                        <MenuItem value={"Brother"}>Brother</MenuItem>
                                        <MenuItem value={"Grandmother"}>Grandmother</MenuItem>
                                        <MenuItem value={"Grandfather"}>Grandfather</MenuItem>
                                        <MenuItem value={"Friend"}>Friend</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={handleSave}>Save Persona Profile</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <AvatarEditorDialog open={dialogOpen} onClose={handleCloseDialog} onSave={handleAvatarSave} />
        </Container>
    );
}

export default PersonaProfile;