import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPersona, getHistory, deleteHistoryItem } from '../api/PersonaApi';
import { getMembershipLevel } from '../api/UserApi';
import PageTitle from '../components/PageTitle/PageTitle';
import HistoryCard from '../components/HistoryCard/HistoryCard';
import { Container, Box, Alert } from '@mui/material';
import { toast } from 'react-toastify';

function PersonaHistory() {
    const { id } = useParams();
    const [persona, setPersona] = useState({ id: '', name: ''});
    const [history, setHistory] = useState([]);
    const [membershipLevel, setMembershipLevel] = useState("Free");

    const fetchData = useCallback(async () => {
        try {
            const personaData = await getPersona(id);
            const historyData = await getHistory(id);

            setPersona(personaData);
            setHistory(historyData);
        } 
        catch (error) {
            toast.error(`Failed to fetch personas: ${error}`);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
        setMembershipLevel(getMembershipLevel());
    }, [fetchData]);

    const handleDeleteHistoryItem = async (historyItem) => {
        try {
            await deleteHistoryItem(persona.id, historyItem.id);

            fetchData();
            
            toast.success("Deleted history entry.");
        }
        catch (error) {
            toast.error(`Failed to fetch personas: ${error}`);
        }
    }

    return (
        <Container component="main" maxWidth="2xl">
            <Box>
                <PageTitle avatar={persona.avatarURL || persona.name.charAt(0)} title={`${persona.name} History`} />
            </Box>
            {membershipLevel === "Free" && 
                <Box mb={2}>
                    <Alert severity="warning">
                        Your membership is free, allowing you to send unlimited messages. 
                        However, you can only view the most recent 10 messages in your chat history, as older messages will not be preserved.
                    </Alert>
                </Box>
            }
            <Box>
                {history.map((item) => (
                    <HistoryCard
                        key={item.id}
                        personaName={persona.name}
                        historyItem={item}
                        onDelete={handleDeleteHistoryItem}
                    />
                ))}
            </Box>
        </Container>
    );
}

export default PersonaHistory;