import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './PersonaCard.scss';
import { Card, CardHeader, CardContent, Avatar, IconButton, Menu, MenuItem, Typography, Button, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function PersonaCard({ persona, onEditEngrams, onManageHistory, onEditProfile, onDelete }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEngrams = () => {
        handleMenuClose();
        onEditEngrams(persona);
    };

    const handleHistory = () => {
        handleMenuClose();
        onManageHistory(persona);
    }

    const handleEditPersonaProfile = () => {
        handleMenuClose();
        onEditProfile(persona);
    };

    const handleDelete = () => {
        handleMenuClose();
        onDelete(persona);
    };

    return (
        <Card sx={{ maxWidth: 345, marginBottom: 2 }}>
            <CardHeader
                avatar={<Avatar aria-label="persona" src={persona.avatarURL || undefined}>{!persona.avatarURL && persona.name.charAt(0)}</Avatar>}
                action={
                    <IconButton aria-label="settings" onClick={handleMenuOpen}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={persona.name}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                    Relationship: {persona.relationship}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <Link to={`/persona/chat/${persona.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Button variant="outlined" color="success" size="small">Start Chat</Button>
                    </Link>
                </Typography>
            </CardContent>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEngrams}>Manage Engrams</MenuItem>
                <MenuItem onClick={handleHistory}>Manage History</MenuItem>
                <Divider />
                <MenuItem onClick={handleEditPersonaProfile}>Edit Persona Profile</MenuItem>
                <Divider />
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </Card>
    );
}

export default PersonaCard;