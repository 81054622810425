import React from 'react';
import PageTitle from '../components/PageTitle/PageTitle';
import { Container, Box, Typography } from '@mui/material';

function Home() {
  return (
    <Container component="main" maxWidth="2xl">
        <Box>
            <PageTitle title="Welcome to Heirloom Chat" />
        </Box>
        <Box mt={4} mb={4}>
            <Typography variant="h6" sx={{ mt: 2, mb: 3 }}>
                Keeping Memories Alive
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                At Heirloom Chat, we believe in preserving the essence of your loved ones through conversation. Our platform enables you to create Memory Engrams—digital representations of past experiences, conversations, and personal details that help you reconnect with those who matter most.
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
                Dive into the past, celebrate the present, and keep your memories alive with every conversation. Start building your personal memory engram today and keep the dialogue going.
            </Typography>
          </Box>
    </Container>
  );
}

export default Home;