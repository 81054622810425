import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getPersona } from '../api/PersonaApi';
import { getMembershipLevel } from '../api/UserApi';
import { getEngrams, deleteEngram, createEngram } from '../api/EngramsApi';
import { Container, Box, Button, Alert, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PageTitle from '../components/PageTitle/PageTitle';
import EngramCard from '../components/EngramCard/EngramCard';
import NewEngramDialog from '../components/NewEngramDialog/NewEngramDialog';
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';
import { toast } from 'react-toastify';

function PersonaEngrams() {
    const { id } = useParams();
    const [persona, setPersona] = useState({ id: '', name: ''});
    const [engrams, setEngrams] = useState([]);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
    const [membershipLevel, setMembershipLevel] = useState("Free");
    const [openExplanationDialog, setOpenExplanationDialog] = useState(false);
    const [selectedEngram, setSelectedEngram] = useState({
        id: '',
        engram: ''
    });

    const fetchData = useCallback(async () => {
        try {
            const data = await getPersona(id);
            const engramData = await getEngrams(id);

            setPersona(data);
            setEngrams(engramData);
        } 
        catch (error) {
            toast.error(`Failed to fetch personas: ${error}`);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
        setMembershipLevel(getMembershipLevel);
    }, [fetchData]);

    const handleNewDialogOpen = () => {
        setIsNewDialogOpen(true);
    };

    const handleNewDialogClose = () => {
        setSelectedEngram({personaId: id, engram: ''});
        setIsNewDialogOpen(false);
    };

    const handleNewDialogSave = async (engram) => {
        try {
            debugger;
            await createEngram(persona.id, engram.engram);

            toast.success("Engram created successfully.");

            fetchData();
        }
        catch (error) {
            toast.error(`${error}`);
        }

        setIsNewDialogOpen(false);
    };

    const handleOpenExplanationDialog = () => {
        setOpenExplanationDialog(true);
    };
      
    const handleCloseExplanationDialog = () => {
        setOpenExplanationDialog(false);
    };

    const promptDeleteEngram = (engram) => {
        setSelectedEngram(engram);
        setIsConfirmDeleteOpen(true);
    };

    const handleDeleteEngram = async (result) => {
        setIsConfirmDeleteOpen(false);
        
        if (result === "Yes") {
            try {
                await deleteEngram(selectedEngram.id)
    
                fetchData();
    
                toast.success("Engram has been deleted.");
            }
            catch (error) {
                toast.error(`Failed to delete engram: ${error}`);
            }
        }
    };

    return (
        <Container component="main" maxWidth="2xl">
            <Box display="flex" alignItems="center" width="100%" mb={2}>
                <PageTitle avatar={persona.avatarURL || persona.name.charAt(0)} title={`${persona.name} Engrams`} />
                <Tooltip title="What is a Memory Engram?">
                    <IconButton onClick={handleOpenExplanationDialog}>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box>
                {membershipLevel === "Free" && 
                    <Box mb={2}>
                        <Alert severity="warning">
                            Your membership is free, allowing you to have no more then two memory engrams.
                        </Alert>
                    </Box>
                }
                <Box mb={2}>
                    <Button variant='contained' onClick={handleNewDialogOpen} disabled={membershipLevel === 'Free' && engrams.length >= 2}>
                        New Engram
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {engrams.map((engram) => (
                        <EngramCard
                            key={engram.id}
                            engram={engram}
                            onDelete={promptDeleteEngram}
                        />
                    ))}
                </Box>
            </Box>
            <NewEngramDialog open={isNewDialogOpen} onClose={handleNewDialogClose} onSave={handleNewDialogSave} engramData={selectedEngram} />
            <ConfirmDialog 
                open={isConfirmDeleteOpen} 
                onClose={handleDeleteEngram} 
                title="Delete engram" 
                message="Are you sure you want to delete this engram? This cannot be undone." 
            />
            <Dialog open={openExplanationDialog} onClose={handleCloseExplanationDialog}>
                <DialogTitle>What is a Memory Engram?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        A Memory Engram functions similarly to how memories are stored in the human brain. Just as neurons in your brain create connections to form memories, 
                        our personas use Memory Engrams to store and recall the personal details, past experiences, and conversations of your loved one.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        <b>Creating a Memory Engram:</b> When you enter information about a loved one—be it a favorite joke, a cherished memory, or specific phrases they 
                        used—you are essentially creating a Memory Engram. Each engram you create adds depth to the persona's understanding, allowing it to converse 
                        and respond in ways that truly reflect the personality and memory of the person you are remembering.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        <b>How It Works:</b> By compiling these details into Memory Engrams, Heirloom Chat can simulate conversations that feel incredibly real and personal. 
                        Each piece of information helps the persona to "learn" about the person it represents, enabling it to mimic their conversational style and react 
                        with appropriate emotions and words.
                    </Typography>
                    <Typography variant="body1">
                        Your contributions to Memory Engrams are vital. They not only enrich the personas interactions but also ensure that the digital representation 
                        of your loved one is as accurate and meaningful as possible.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExplanationDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default PersonaEngrams;