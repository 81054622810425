import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Home from './pages/Home';
import About from './pages/About';
import Personas from './pages/Personas';
import PersonaChat from './pages/PersonaChat';
import PersonaEngrams from './pages/PersonaEngrams';
import PersonaHistory from './pages/PersonaHistory';
import PersonaProfile from './pages/PersonaProfile';
import Login from './pages/Login';
import Register from './pages/Register';
import VerifyUser from './pages/VerifyUser';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import Membership from './pages/Membership';
import NotFound from './pages/NotFound';

function App () {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="personas" element={
              <ProtectedRoute>
                <Personas />
              </ProtectedRoute>
            } />
            <Route path="persona/chat/:id" element={
              <ProtectedRoute>
                <PersonaChat />
              </ProtectedRoute>
            } />
            <Route path="persona/engrams/:id" element={
              <ProtectedRoute>
                <PersonaEngrams />
              </ProtectedRoute>
            } />
            <Route path="persona/history/:id" element={
              <ProtectedRoute>
                <PersonaHistory />
              </ProtectedRoute>
            } />
            <Route path="persona/profile/:id" element={
              <ProtectedRoute>
                <PersonaProfile />
              </ProtectedRoute>
            } />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="verify/:userToken" element={<VerifyUser />} />
            <Route path="forgot/" element={<ForgotPassword />} />
            <Route path="forgot/:userToken" element={<ResetPassword />} />
            <Route path="profile" element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            } />
            <Route path="membership" element={
              <ProtectedRoute>
                <Membership />
              </ProtectedRoute>
            } />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;